
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {computed, defineComponent, onMounted, watch} from "vue";
import store from "@/store";
import ClaimForm from "@/views/claim/ClaimForm.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {onUpdated} from "@vue/runtime-core";
import router from "@/router";

export default defineComponent({
  name: "Update",
  components: {ClaimForm},
  setup() {
    const claim = computed(() => store.state.ClaimModule.claim)
    watch(claim, (cb) => {
      store.dispatch(Actions.LOAD_POLICY, cb.policy.id)
    })
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Update Claim', [
        {link: true, router: '/claims', text: 'Search Claim'},
        {link: true, router: '/claims/' + claim?.value?.id, text: 'View Claim'},
        {link: false, router: '', text: 'Update Claim'}
      ])
    })
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Update Claim', [
        {link: true, router: '/claims', text: 'Search Claim'},
        {link: true, router: '/claims/' + claim?.value?.id, text: 'View Claim'},
        {link: false, router: '', text: 'Update Claim'}
      ])
    })

    const onUpdatedClaim = (ca) => {
      router.push({
        name: "claimOverview",
        params: {id: ca.id}
      });
    }
    return {
      ...LoadEntity(Actions.LOAD_CLAIM),
      claim,
      onUpdatedClaim,
    }
  }
})
