
import {computed, defineComponent, ref} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import store from "@/store";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import ClaimService from "@/core/services/ClaimService";
import {CaseSelectFilter, ExaminerSelect, HandleState, PPOSelect} from "@/core/composite/composite";
import Froala from "@/components/base/editor/Froala.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import * as Yup from "yup";
import {Field} from "vee-validate";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {ILocation} from "@/core/entity/ILocation";
import {ModelAddress} from "@/core/entity/IAddress";
export default defineComponent({
  name: "ClaimForm",
  components: {FormErrorMessage, DateTimeSelect, Froala, BaseSelect, BaseForm, Field},
  props: {
    claimId: {type: String, required: true},
    policyId: {type: String, required: true},
    claim: {type: Object}
  },
  emits: ['claimSaved'],
  setup(props, {emit}) {
    const validate = Yup.object().shape({
      // email: Yup.string().email().label("Email"),
    });
    const submitting = ref(false);
    const lockInsured = ref(false);
    const policy = computed(() => store.state.PolicyModule.policy)
    const model = ref({
      policyId: props.policyId,
      cazeId: props.claim?.cazeId,
      insuredId: props.claim?.insuredId,
      examinerId: props.claim?.examinerId,
      incidentDate: props.claim?.incidentDate,
      details: props.claim?.details,
      transferReserve: props.claim?.transferReserve,
      refNum: props.claim?.refNum,
      address: props?.claim?.address ? props.claim.address : new ModelAddress(),
      symptoms: props.claim?.symptoms,
      networkId: props?.claim?.networkId,
    })
    const claimSave = () => {
      submitting.value = true
      if (props.claimId) {
        ClaimService.update(props.claimId, model.value).then(res => {
          emit('claimSaved', res);
        }).finally(() => {
          submitting.value = false
        })
      } else {
        ClaimService.create(model.value).then(res => {
          emit('claimSaved', res);
        }).finally(() => {
          submitting.value = false
        })
      }
    }

    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    return {
      countries,
      states,
      validate,
      submitting,
      policy,
      model,
      lockInsured,
      claimSave,
      ...PPOSelect(),
      ...HandleState(),
      ...ExaminerSelect(),
      ...CaseSelectFilter({policyId: props.policyId})
    }
  },
  methods: {
    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name) this.model.address.stateName = name
    },

    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name) this.model.address.countryName = name;
    },
    onSelectedCase(caseId: string) {
      const caze = this.cases.find(item => item.id === caseId);
      if (caze) {
        this.lockInsured = true
        this.model.insuredId = caze.insured;
      } else {
        this.lockInsured = false
      }
    }
  }
})
