
import { defineComponent } from "vue";
import {ErrorMessage} from "vee-validate";

export default defineComponent({
  name: "FormErrorMessage",
  components: {ErrorMessage},
  props: {
    name: {required: true, type: String}
  }
})
